<template>
    <b-tabs align="center">
        <b-tab>
            <template #title>
                <feather-icon icon="HomeIcon" />
                <span>Main</span>
            </template>
            <b-card>
                <div class="row">
                    <div class="col-md-8 maxheight" v-if="liveVideoStatus == 0">
                        <vplayer :playerOptions="vOption" class="maxheight" preload="false" />
                        <div class="mt-2 justify-content-center row mb-4">
                            <b-button class="col-md-3 col-sm-5" @click="startRecord" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="relief-info"> Start Recording 🎥</b-button>
                            <b-button class="col-md-3 col-sm-5" @click="check" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="relief-success"> Upload Recorded Live</b-button>
                        </div>
                    </div>
                    <div class="col-md-8 secondMaxheight" v-else>
                        <vplayer :playerOptions="vOption" class="maxheight" preload="false" />
                        <vplayer v-if="secondPlayerAllowed" :playerOptions="streamervOption" class="maxheight mt-2" preload="false" />
                    </div>
                    <div v-if="onAir" class="col-md-4">
                        <div v-if="lastPinnedAllowed == 1" class="messages mb-50">
                            <div class="row">
                                <div class="col-3 my-50">
                                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                        <template #button-content>
                                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                        </template>
                                        <b-dropdown-item class="text-justify" @click="unPinMsg(lastPinnedMessages.data.id)">
                                            <feather-icon icon="PaperclipIcon" />
                                            <span class="mx-50">Unpin</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="col-9 text-right my-1">
                                    <strong class="text-dark mx-50">{{ lastPinnedMessages.data.fromUser.name }}</strong>
                                    <b-avatar size="35" :src="lastPinnedMessages.data.fromUser.thumbnail" />
                                    <feather-icon icon="PaperclipIcon" size="16" class="ml-2" />
                                </div>
                            </div>
                            <div class="col-10 mb-1 font-weight-bold text-light text-right">
                                <p id="parag">{{ lastPinnedMessages.data.message }}</p>
                                <b-avatar class="mr-4" v-if="lastPinnedMessages.data.message_type == 'sticker'" size="80" :src="lastPinnedMessages.data.sticker.image" />
                            </div>
                        </div>
                        <div ref="chatContainer" id="chatSec" class="scrollarea d-flex flex-column align-items-stretch flex-shrink-0 chat-background">
                            <div class="messages" v-for="message in messages" :key="message.id" :ref="'message' + message.id">
                                <div class="row">
                                    <div class="col-3 my-50">
                                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                            <template #button-content>
                                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                            </template>
                                            <b-dropdown-item class="text-justify" @click="pinMsg(message.id)">
                                                <feather-icon icon="PaperclipIcon" />
                                                <span class="mx-50">Pin</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item class="text-justify" @click="banUser(message.fromUser.id, message.live_id)">
                                                <feather-icon icon="UserXIcon" />
                                                <span class="mx-50">Ban User</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item class="text-justify" @click="deleteMsgHandler(message.id)">
                                                <feather-icon icon="SlashIcon" />
                                                <span class="mx-50">Delete Message </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <div class="col-9 text-right my-1">
                                        <b-img v-if="message.fromUser.badges.includes('Celebrity')" class="mb-1 mb-sm-0" height="10" src="./images/celebrity.png" alt="Left image" />
                                        <b-img v-if="message.fromUser.badges.includes('Corporate')" class="mb-1 mb-sm-0" height="10" src="./images/corporate.png" alt="Left image" />
                                        <b-img v-if="message.fromUser.badges.includes('Fashionista')" class="mb-1 mb-sm-0" height="10" src="./images/fashionista.png" alt="Left image" />
                                        <b-img v-if="message.fromUser.badges.includes('Verification')" class="mb-1 mb-sm-0" height="10" src="./images/Badge.png" alt="Left image" />
                                        <b-img v-if="message.fromUser.badges.includes('VIP')" class="mb-1 mb-sm-0" height="10" src="./images/vip.png" alt="Left image" />
                                        <strong class="mx-50 text-light">{{ message.fromUser.name }}</strong>
                                        <b-avatar size="35" :src="message.fromUser.thumbnail" />
                                    </div>
                                </div>
                                <div class="col-12 mb-1 font-weight-bold text-right">
                                    <p id="parag">{{ message.message }}</p>
                                    <b-avatar class="mr-4" v-if="message.message_type == 'sticker'" size="80" :src="message.sticker.image" />
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <b-button v-if="HasNewMessage" id="msgButton" variant="" class="btn-icon rounded-circle" @click="goToBottom">
                                <feather-icon icon="ArrowDownIcon" :badge="newMessageCount" size="18" />
                            </b-button>
                        </div>
                        <div class="row mt-50">
                            <div class="col-4 mt-50">
                                <span>Online Pannel Users:</span>
                            </div>
                            <h5 class="col-2 mt-50">{{ liveCount }}</h5>

                            <div class="col-4 mt-50">
                                <span>Online App Users:</span>
                            </div>
                            <h5 class="col-2 mt-50">{{ appCount }}</h5>
                        </div>
                        <div class="row mt-50 justify-content-start">
                            <div class="col-5 mt-50">
                                <span>Online App Promoted Users:</span>
                            </div>
                            <h5 class="col-2 mt-50">{{ appFakeCount }}</h5>
                            <div class="col-3 mt-50">
                                <span>Live Likes:</span>
                            </div>
                            <h5 class="col-2 mt-50 text-danger">{{ likeCount }}</h5>
                        </div>

                        <form class="mt-1" @submit.prevent="submit">
                            <b-overlay :show="overLayShow" class="">
                                <input class="form-control" placeholder="Write a message" v-model="message" />
                            </b-overlay>
                        </form>

                        <div class="row justify-content-around m-1">
                            <!-- activeChatHandler -->
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="activeChatHandler"> Active </b-button>
                            <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" class="btn-icon" @click="openModal">
                            <feather-icon icon="MaximizeIcon" size="16" />
                        </b-button> -->
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="deActiveChatHandler"> Deactive </b-button>
                        </div>

                        <!-- <div class="row justify-content-center">
                        <b-button class="col-11" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="relief-warning" @click="popUpBannerAllow"> PopUp Banner </b-button>
                    </div> -->
                        <hr />
                        <!-- <div class="row justify-content-center">
                        <b-button class="mx-25" @click="showBanUsersHandler" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning" pill>
                            <feather-icon icon="UserXIcon" />
                            <span class="align-middle">Banned Users</span>
                        </b-button>
                        <b-button class="mx-25" @click="showLiveStreamDataHandler" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-info" pill>
                            <feather-icon icon="UserXIcon" class="mr-50" />
                            <span class="align-middle">Live Stream Data</span>
                        </b-button>
                    </div> -->
                    </div>
                    <div v-else>
                        <h4>there is no on air chat</h4>
                    </div>
                    <b-modal size="xl" v-model="banUsersAllowed" title="Ban Users" ok-title="Close" ok-only> </b-modal>
                    <b-modal size="lg" v-model="popUpAllowed" title="Popup Banner" ok-title="Close" ok-only> </b-modal>
                    <b-modal size="lg" v-model="liveStreamDataAllowed" title="Live Stream Data" ok-title="Close" ok-only> </b-modal>
                    <b-toast>
                        <template #toast-title>
                            <div class="d-flex flex-grow-1 align-items-center mr-1">
                                <!-- <b-img :src="require('@/assets/images/logo/logo.png')" class="mr-1" height="18" width="25" alt="Toast image" /> -->
                                <strong class="mr-auto">Vue Admin</strong>
                                <small class="text-muted">11 mins ago</small>
                            </div>
                        </template>
                        <span>Hello, world! This is a toast message. Hope you're doing well.. :)</span>
                    </b-toast>
                </div>
            </b-card>
        </b-tab>
        <b-tab @click="openModal">
            <template #title>
                <feather-icon icon="ListIcon" />
                <span>Chats</span>
            </template>
            <div class="row mt-50 justify-content-center">
                <div class="col-2 mt-50">
                    <span>Online Users:</span>
                </div>
                <h4 class="col-1 mt-50 text-warning">{{ appCount }}</h4>
            </div>

            <div v-if="lastPinnedAllowed == 1" class="messages mb-50">
                <div class="row">
                    <div class="col-3 my-50">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>
                            <b-dropdown-item class="text-justify" @click="unPinMsg(lastPinnedMessages.data.id)">
                                <feather-icon icon="PaperclipIcon" />
                                <span class="mx-50">Unpin</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="col-9 text-right my-1">
                        <strong class="text-dark mx-50">{{ lastPinnedMessages.data.fromUser.user_name }}</strong>
                        <b-avatar size="30" :src="lastPinnedMessages.data.fromUser.thumbnail" />
                        <feather-icon icon="PaperclipIcon" size="16" class="ml-2" />
                    </div>
                </div>
                <div class="col-10 mb-1 font-weight-bold text-light text-right">
                    <p id="parag">{{ lastPinnedMessages.data.message }}</p>
                    <b-avatar class="mr-4" v-if="lastPinnedMessages.data.message_type == 'sticker'" size="80" :src="lastPinnedMessages.data.sticker.image" />
                </div>
            </div>
            <div ref="modalChatContainer" id="chatSec" class="scrollarea d-flex flex-column align-items-stretch flex-shrink-0 chat-background modalMsgHeight">
                <div class="messages" v-for="message in modalMessages" :key="message.id" :ref="'message' + message.id">
                    <div class="row">
                        <div class="col-3 my-50">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item class="text-justify" @click="pinMsg(message.id)">
                                    <feather-icon icon="PaperclipIcon" />
                                    <span class="mx-50">Pin</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="text-justify" @click="banUser(message.fromUser.id, message.live_id)">
                                    <feather-icon icon="UserXIcon" />
                                    <span class="mx-50">Ban User</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="text-justify" @click="deleteMsgHandler(message.id)">
                                    <feather-icon icon="SlashIcon" />
                                    <span class="mx-50">Delete Message </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="col-9 text-right my-1">
                            <div class="row justify-content-end mr-2">
                                <h3 class="text-dark mx-50 mt-25">{{ message.fromUser.name }}</h3>
                                <b-avatar size="40" :src="message.fromUser.thumbnail" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-1 font-weight-bold text-light text-right">
                        <p id="parag" class="m-1 modalparag">{{ message.message }}</p>
                        <b-avatar class="mr-4" v-if="message.message_type == 'sticker'" size="80" :src="message.sticker.image" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <b-button v-if="HasNewMessage" id="msgButton" variant="light" class="btn-icon rounded-circle" @click="goToModalBottom">
                    <feather-icon icon="ArrowDownIcon" :badge="newMessageCount" size="18" />
                </b-button>
            </div>
            <form class="mt-1" @submit.prevent="modalSubmit">
                <input class="form-control" placeholder="Write a message" v-model="message" />
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="m-2" type="submit">Send</b-button>
            </form>
        </b-tab>
        <b-tab @click="showBanUsersHandler">
            <template #title>
                <feather-icon icon="UserXIcon" />
                <span>Banned Users</span>
            </template>
            <b-card>
                <div class="row justify-content-center mt-1" v-for="(item, index) in banUsersList" :key="item.baned_user.id">
                    <div class="col-12 bannedList">
                        <div class="row justify-content-between">
                            <h5 class="m-1">{{ index + 1 }}</h5>
                            <span class="mt-1 ml-2">{{ item.baned_user.user_name }}</span>
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item class="text-justify" @click="ubanHandler(item.baned_user.id)">
                                    <feather-icon icon="PaperclipIcon" />
                                    <span class="mx-50">UnBan</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-tab>
        <b-tab @click="popUpBannerAllow">
            <template #title>
                <feather-icon icon="PrinterIcon" />
                <span>PopUp Banner</span>
            </template>
            <b-card>
                <div class="row justify-content-center">
                    <div class="col-8 mx-1">
                        <div class="row justify-content-center">
                            <b-card-text class="row justify-content-center font-weight-bolder mr-3">
                                Status:
                                <span v-if="bannerStatus == true" class="text-success ml-1"> Is Showing </span>
                                <span v-else class="text-danger ml-1"> Is not Showing </span>
                            </b-card-text>
                        </div>
                        <label class="row justify-content-center mt-1" for="homeBannner"> Popup Banner </label>
                        <image-cropper name="homeBannner" class="col-12 mt-1" v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />

                        <b-card-text class="row justify-content-center">Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                        <div class="row justify-content-center">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="col-sm-3" @click="hideBannerHandler">Hide</b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="col-sm-3" @click="updateBannerHandler">Upload</b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="col-sm-3" @click="showBannerHandler">Show</b-button>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <feather-icon icon="HeartIcon" />
                <span>Live Likes</span>
            </template>
            <b-card>
                <div class="row justify-content-center m-5">
                    <div class="col-md-3">
                        <label for="likes">Live Likes count</label>
                        <b-form-input name="likes" id="Like Count" v-model="liveLikeCount" />
                    </div>
                    <b-button class="mx-50 mt-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="sendLiveLikes">Send</b-button>
                    <b-button class="mx-50 mt-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" @click="resetLiveLikes">Reset</b-button>
                </div>
            </b-card>
        </b-tab>
        <b-tab @click="showLiveStreamDataHandler">
            <template #title>
                <feather-icon icon="DatabaseIcon" />
                <span>Live Stream Data</span>
            </template>
            <b-card>
                <div class="row justify-content-center">
                    <div class="col-7">
                        <label for="host">Host</label>
                        <b-form-input name="host" v-model="liveStreamData.host" placeholder="Enter Host" />
                        <label for="streamKey">Stream Key</label>
                        <b-form-input name="streamKey" v-model="liveStreamData.stream_key" placeholder="Enter Stream Key" />
                        <label for="showURL">Show Url</label>
                        <b-form-input name="showURL" v-model="liveStreamData.show_url" placeholder="Enter Show Url" />
                        <div class="row justify-content-center">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="col-sm-3 mt-1" @click="storeLiveStreamDataHandler">Submit</b-button>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-tab>
        <b-tab @click="liveStreamRequestsHandler">
            <template #title>
                <feather-icon icon="UserCheckIcon" />
                <span>Live Stream Requests</span>
            </template>
            <div class="row justify-content-center">
                <div class="col-md-7">
                    <b-card>
                        <h3 class="font-weight-bolder text-center mb-2" v-if="liveStreamActiveRequest != null">Active Request</h3>
                        <div class="row justify-content-center" v-if="liveStreamActiveRequest != null">
                            <div class="col-5 font-weight-bold">
                                {{ liveStreamActiveRequest.user.name }}
                            </div>
                            <div class="row">
                                <div class="mx-1">
                                    <b-badge variant="light-success">
                                        {{ liveStreamActiveRequest.status }}
                                    </b-badge>
                                </div>
                                <b-button size="sm" @click="cancelInviteHandler(liveStreamActiveRequest.id, liveStreamActiveRequest.user.id)" v-if="liveStreamActiveRequest.status == 'invited|pending'" variant="gradient-danger"> cancel invite </b-button>
                                <b-button v-else variant="gradient-danger" size="sm" @click="endStreamHandler(liveStreamActiveRequest.id, liveStreamActiveRequest.user.id)"> End Stream </b-button>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-7">
                    <b-card>
                        <h3 class="font-weight-bolder text-center">Requesting</h3>
                        <div class="row justify-content-between mt-1" v-for="item in liveStreamRequestsList" :key="item.id">
                            <div class="col-4">
                                {{ item.user.name }}
                            </div>
                            <div class="col-3">
                                <b-badge variant="light-primary">
                                    <span class="font-weight-bold">
                                        {{ item.status }}
                                    </span>
                                </b-badge>
                                <b-badge class="ml-1 badge-glow cursor-pointer" variant="success" @click="inviteHandler(item.id, item.user.id)">
                                    <feather-icon icon="CheckIcon" />
                                </b-badge>
                                <b-badge class="ml-1 badge-glow cursor-pointer" variant="danger" @click="removeInviteHandler(item.id)">
                                    <feather-icon icon="XIcon" />
                                </b-badge>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
            <!-- <b-card>
                <div class="row justify-content-center">
                    <div class="col-12 strearequest">
                        <hr />
                    </div>
                </div>
            </b-card> -->
        </b-tab>
    </b-tabs>
</template>
<script>
import { BOverlay, BImg, BFormFile, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BModal, BToast, BFormCheckbox, BCardText, BTabs, BTab } from "bootstrap-vue";
import { nextTick, onUnmounted, ref, onMounted, watch, toRef, reactive, watchEffect } from "@vue/composition-api";
import { vplayer } from "vue-hls-player";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import useLiveChatList from "./useLiveChatList";
import Ripple from "vue-ripple-directive";
// import VueChatScroll from "vue-chat-scroll";
import liveRepository from "@/abstraction/repository/liveChatRepository.js";
import useFile from "../file/useFile";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
const repository = new liveRepository();
export default {
    components: {
        BTabs,
        BTab,
        BOverlay,
        BToast,
        BImg,
        vplayer,
        BCard,
        BModal,
        BRow,
        BCardText,
        BFormCheckbox,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BFormFile,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const streamerSrc = ref();
        const vOption = ref({
            type: "application/x-mpegURL",
            src: "https://hls.nojoumtv.net/hls/Live.m3u8",
            // src: "",
            preload: true,
            autoplay: true,
            isLoop: false,
            poster: "https://oimdztrab.qnssl.com/Frp4SyVe5PosdkUKRaE-krjK7B5z",
            controls: "progress,current,durration,volume",
            crossOrigin: false,
        });
        const streamervOption = ref({
            type: "application/x-mpegURL",
            src: streamerSrc.value,
            preload: true,
            autoplay: true,
            isLoop: false,
            poster: "https://oimdztrab.qnssl.com/Frp4SyVe5PosdkUKRaE-krjK7B5z",
            controls: "progress,current,durration,volume",
            crossOrigin: false,
        });
        const pause = (e) => {
            console.log(e);
        };
        const state = reactive({
            messages: [],
        });
        const modalState = reactive({
            messages: [],
        });
        const messagesRef = toRef(state, "messages");
        const chatContainer = ref(null);
        const modalChatContainer = ref(null);
        const modalAllowed = ref(false);
        const banUsersAllowed = ref(false);
        const connection = ref(null);
        const onAir = ref(false);
        const banUsersList = ref();
        const chatId = ref();
        const HasNewMessage = ref(false);
        const newMessageCount = ref(0);
        const liveLikeCount = ref(0);
        const likeCount = ref(null);
        const popUpAllowed = ref(false);
        const overLayShow = ref(false);
        const liveStreamDataAllowed = ref(false);
        const liveStreamRequestsList = ref(null);
        let blankForm = {
            title: "",
            file_batch_id: RANDOM_TOKEN,
        };
        const bannerStatus = ref(null);
        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "ads_image", "live_chats");
        const { pinedMessages, unPinMessage, pinMessage, liveId, addNewMessage, banLiveChatUser, showActiveLive, activeChat, deActiveChat, finishChat, uploadVideo, addLiveVideo, fetchLiveLastMessages, deleteMsg, showBanUsers, unbanLiveChatUser, storeLiveStreamData, showLiveStreamData, showLiveStreamRequests, inviteUserToStream, cancelInvite, endStream, removeRequests } = useLiveChatList();
        const liveVideoStatus = ref();
        const secondPlayerAllowed = ref(false);
        const showActiveLiveHandler = async () => {
            await showActiveLive().then((res) => {
                liveVideoStatus.value = res.data.has_second_streamer;
                vOption.value.src = res.data.data.link;
                chatId.value = res.data.data.id;
                streamervOption.value.src = res.data.show_url;

                streamerSrc.value = res.data.show_url;
                if (res.data.data.status == "on air") {
                    onAir.value = true;
                }
            });
        };
        console.log(streamervOption.value.src);
        console.log(streamerSrc.value);
        const liveStreamData = ref({
            live_id: chatId.value,
        });
        const videoUrl = ref();
        // Live Chat
        const username = ref("username");
        const messages = ref([]);
        const message = ref("");
        const test = ref(true);
        const sentMsgCount = ref(0);
        const modalSentMsgCount = ref(0);
        const modalSubmit = async () => {
            let data = {
                live_id: chatId.value,
                message: message.value,
                message_type: "message",
            };
            addNewMessage(data).then((re) => {
                message.value = "";
                modalSentMsgCount.value++;
                console.log(sentMsgCount.value);
            });
        };
        const submit = async () => {
            let data = {
                live_id: chatId.value,
                message: message.value,
                message_type: "message",
            };
            overLayShow.value = true;
            addNewMessage(data).then((re) => {
                message.value = "";
                sentMsgCount.value++;
                overLayShow.value = false;
            });
        };

        let token = `Bearer ${localStorage.getItem("accessToken")}`;
        const liveCount = ref();
        const appCount = ref();
        const appFakeCount = ref();
        const lastPinnedMessages = ref();
        const lastPinnedAllowed = ref();

        onMounted(async () => {
            const channelName = ref();
            if (window.location.hostname === "dev.nojoum.app" || window.location.hostname === "localhost") {
                channelName.value = "staging_";
            } else if (window.location.hostname === "app.nojoum.app") {
                channelName.value = "";
            }
            await showActiveLiveHandler().then((res) => {
                secondPlayerAllowed.value = true;
            });
            liveId.value = chatId.value;
            await fetchLiveLastMessages().then((res) => {
                res.value.data.reverse().map((item) => {
                    state.messages.push(item);
                    modalState.messages.push(item);
                });
                scrollchat();
                //  modalScrollchat();
            });
            await pinedMessages(liveId.value).then((res) => {
                lastPinnedMessages.value = res.data;
                lastPinnedAllowed.value = res.data.has_pinned_message;
            });
            connection.value = new WebSocket("wss://echo.websocket.org");
            connection.value.onmessage = () => {};
            Pusher.logToConsole = true;
            const pusher = new Pusher("c709e9abca9c982a8865", {
                cluster: "mt1",
            });
            const channel = pusher.subscribe(`${channelName.value}nojoum_panel_live_chat.${chatId.value}`);
            channel.bind("message-sent", async (data) => {
                const newMessage = data.message;
                const isAtBottom = chatContainer.value.scrollHeight - chatContainer.value.clientHeight <= chatContainer.value.scrollTop + 1;
                const threshold = 400;
                const isNearBottom = chatContainer.value.scrollHeight - chatContainer.value.clientHeight - chatContainer.value.scrollTop <= threshold;

                if (isAtBottom || isNearBottom) {
                    scrollchat();
                    HasNewMessage.value = false;
                    newMessageCount.value = 0;
                } else {
                    HasNewMessage.value = true;
                    newMessageCount.value++;
                }
                await state.messages.push(newMessage);
            });
            channel.bind("message-sent", async (data) => {
                const newMessage = data.message;
                const modalIsAtBottom = modalChatContainer.value.scrollHeight - modalChatContainer.value.clientHeight <= modalChatContainer.value.scrollTop + 1;
                const threshold = 600;
                const isNearBottom = modalChatContainer.value.scrollHeight - modalChatContainer.value.clientHeight - modalChatContainer.value.scrollTop <= threshold;

                if (modalIsAtBottom || isNearBottom) {
                    modalScrollchat();
                    HasNewMessage.value = false;
                    newMessageCount.value = 0;
                } else {
                    HasNewMessage.value = true;
                    newMessageCount.value++;
                }
                await modalState.messages.push(data.message);
            });
            channel.bind("message-pinned", async (data) => {
                await pinedMessages(liveId.value).then((res) => {
                    lastPinnedMessages.value = res.data;
                    lastPinnedAllowed.value = res.data.has_pinned_message;
                });
            });
            channel.bind("message-unpinned", (data) => {
                lastPinnedAllowed.value = 0;
            });
            channel.bind("pusher:subscription_count", (data) => {
                liveCount.value = data.subscription_count;
            });
            channel.bind("live-likes-count", (data) => {
                likeCount.value = data.data.likes_count;
            });
            channel.bind("new-stream-request", (data) => {
                liveStreamRequestsList.value.push(data.stream_request);
            });
            channel.bind("cancel-stream-request", (data) => {
                let index = liveStreamRequestsList.value.findIndex((item) => {
                    return item.id === data.stream_request.id;
                });
                console.log("IIINDEX", index);
                liveStreamRequestsList.value.splice(index, 1);
            });
            channel.bind("active-stream-request-updated", (data) => {
                liveStreamActiveRequest.value = data.active_request;
            });
            const appChannel = pusher.subscribe(`${channelName.value}live_chat.${chatId.value}`);
            appChannel.bind("pusher:subscription_count", (data) => {
                appCount.value = data.subscription_count;
            });
            appChannel.bind("live-subscription-count", (data) => {
                appFakeCount.value = data.data.subscription_count;
                likeCount.value = data.data.likes_count;
            });
        });
        const openModal = () => {
            // modalAllowed.value = !modalAllowed.value;
            fetchLiveLastMessages().then((res) => {
                res.value.data.reverse().map((item) => {
                    state.messages.push(item);
                    modalState.messages.push(item);
                });
            });
            modalScrollchat();
        };
        const scrollchat = () => {
            setTimeout(() => {
                const lastMessage = chatContainer.value.querySelector(`.messages:last-child`);
                if (lastMessage) {
                    const chatContainerHeight = chatContainer.value.offsetHeight;
                    const lastMessageHeight = lastMessage.offsetHeight;
                    chatContainer.value.scrollTop = chatContainer.value.scrollHeight - chatContainerHeight + lastMessageHeight;
                }
                console.log("Ejra");
            }, 100);
        };
        const modalScrollchat = () => {
            setTimeout(() => {
                const lastMessage = modalChatContainer.value.querySelector(`.messages:last-child`);
                if (lastMessage) {
                    const chatContainerHeight = modalChatContainer.value.offsetHeight;
                    const lastMessageHeight = lastMessage.offsetHeight;
                    modalChatContainer.value.scrollTop = modalChatContainer.value.scrollHeight - chatContainerHeight + lastMessageHeight;
                }
                console.log("ModalEjra");
            }, 3000);
        };
        const checkScroll = () => {
            console.log(chatContainer.value.offsetHeight, chatContainer.value.scrollHeight, chatContainer.value.scrollTop);
            const isAtBottom = chatContainer.value.scrollHeight - chatContainer.value.clientHeight <= chatContainer.value.scrollTop + 1;
            console.log(isAtBottom);
        };

        const goToBottom = () => {
            chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
            HasNewMessage.value = false;
            newMessageCount.value = 0;
        };
        const goToModalBottom = () => {
            modalChatContainer.value.scrollTop = modalChatContainer.value.scrollHeight;
            HasNewMessage.value = false;
            newMessageCount.value = 0;
        };
        watch(sentMsgCount, () => {
            scrollchat();
        });
        watch(modalSentMsgCount, () => {
            modalScrollchat();
        });
        const banUser = (userId, liveId) => {
            banLiveChatUser(userId, liveId).then((res) => {});
        };
        const deleteMsgHandler = (id) => {
            deleteMsg(id);
        };
        const activeChatHandler = () => {
            console.log(chatId.value);
            activeChat(chatId.value).then((res) => {
                console.log(res);
            });
        };
        const deActiveChatHandler = () => {
            deActiveChat(chatId.value).then((res) => {
                console.log(res);
            });
        };
        const finishChatHandler = () => {
            finishChat(chatId.value).then((res) => {
                console.log(res);
            });
        };

        const showBanUsersHandler = async () => {
            await showBanUsers(chatId.value).then((res) => {
                banUsersList.value = res;

                console.log(res);
            });
            // banUsersAllowed.value = true;
        };
        const ubanHandler = (userId) => {
            unbanLiveChatUser(userId, chatId.value).then((res) => {
                banUsersAllowed.value = false;
            });
        };
        const sendLiveLikes = () => {
            let data = {
                likes_count: liveLikeCount.value,
            };
            repository.liveLikes(chatId.value, data);
        };
        const resetLiveLikes = () => {
            repository.resetLiveLikes(chatId.value)
        };
        const popUpBannerAllow = () => {
            // popUpAllowed.value = true;
            repository.liveBannerStatus(chatId.value).then((res) => {
                console.log(res);
                (form.value.thumbnail = res.ads_thumbnail), (bannerStatus.value = res.show_ads);
            });
        };
        const updateBannerHandler = async () => {
            if (fileForm.value.file) {
                await storeFile();
            }
            let data = form.value;
            repository.updateLiveBanner(chatId.value, data).then((res) => {
                console.log(res);
                popUpAllowed.value = false;
            });
        };

        const hideBannerHandler = async () => {
            repository.hideLiveBanner(chatId.value).then((res) => {
                console.log(res);
                popUpAllowed.value = false;
            });
        };
        const showBannerHandler = async () => {
            repository.showLiveBanner(chatId.value).then((res) => {
                console.log(res);
                popUpAllowed.value = false;
            });
        };
        const showLiveStreamDataHandler = async () => {
            showLiveStreamData(chatId.value).then((res) => {
                liveStreamData.value = res;
            });
        };
        const storeLiveStreamDataHandler = async () => {
            liveStreamData.value.live_id = chatId.value;
            console.log(liveStreamData.value);
            console.log(chatId.value);
            storeLiveStreamData(liveStreamData.value);
        };
        const liveStreamActiveRequest = ref(null);
        const liveStreamRequestsHandler = async () => {
            showLiveStreamRequests(chatId.value).then((res) => {
                console.log(res);
                liveStreamRequestsList.value = res.requests;
                liveStreamActiveRequest.value = res.active_request;
            });
        };
        const inviteHandler = async (reqId, userId) => {
            let data = {
                request_id: reqId,
                live_id: chatId.value,
                user_id: userId,
            };
            inviteUserToStream(data);
        };
        const cancelInviteHandler = async (reqId, userId) => {
            let data = {
                request_id: reqId,
                live_id: chatId.value,
                user_id: userId,
            };
            cancelInvite(data);
        };
        const endStreamHandler = async (reqId, userId) => {
            endStream(chatId.value, userId);
        };
        const removeInviteHandler = async (id) => {
            removeRequests(id);
        };
        // Screen Record
        const recordedVideo = ref();
        const uploadedFile = ref();
        const videoFile = ref([]);
        const check = async () => {
            // console.log(uploadedFile.value);
            // const fd = new FormData();
            // fd.append("name", uploadedFile.value.name);
            // fd.append("file", uploadedFile.value);

            // uploadVideo(fd).then((res) => {
            //     console.log(res);
            //     addLiveVideo(chatId.value, res.data.id);
            // });
            const fd = new FormData();
            fd.append("name", "video01");
            fd.append("file", videoFile.value[0]);
            uploadVideo(fd).then((res) => {
                console.log(res);
                addLiveVideo(chatId.value, res.data.id);
            });
        };
        const pinMsg = async (id) => {
            pinMessage(id);
        };
        const unPinMsg = async (id) => {
            console.log(id);
            unPinMessage(id).then((res) => {
                lastPinnedAllowed.value = 0;
            });
        };
        const startRecord = async () => {
            let stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
            });

            const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9") ? "video/webm; codecs=vp9" : "video/webm";
            let mediaRecorder = new MediaRecorder(stream, {
                mimeType: mime,
            });

            let chunks = [];
            mediaRecorder.addEventListener("dataavailable", function (e) {
                videoFile.value.push(e.data);
            });

            mediaRecorder.addEventListener("stop", function () {
                let blob = new Blob(videoFile.value, {
                    type: videoFile.value[0].type,
                });

                // let video = document.querySelector("video");
                // recordedVideo.value = URL.createObjectURL(blob);

                let a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.download = "video.webm";
                a.click();
            });

            mediaRecorder.start();
            console.log(recordedVideo.value);
        };

        return {
            onAir,
            vOption,
            pause,
            username,
            // messages,
            messages: state.messages,
            modalMessages: modalState.messages,
            message,
            submit,
            modalSubmit,
            banUser,
            activeChatHandler,
            deActiveChatHandler,
            finishChatHandler,
            liveCount,
            appCount,
            appFakeCount,
            HasNewMessage,
            openModal,
            newMessageCount,
            goToBottom,
            goToModalBottom,
            //screen record data
            // isRecording,
            // options,
            startRecord,
            recordedVideo,
            check,
            uploadedFile,
            deleteMsgHandler,
            pinMsg,
            unPinMsg,
            lastPinnedMessages,
            lastPinnedAllowed,
            modalAllowed,
            chatContainer,
            modalChatContainer,
            checkScroll,
            banUsersAllowed,
            showBanUsersHandler,
            banUsersList,
            ubanHandler,
            overLayShow,
            liveStreamDataAllowed,
            liveStreamData,
            showLiveStreamDataHandler,
            storeLiveStreamDataHandler,
            liveStreamRequestsHandler,
            liveStreamRequestsList,
            liveStreamActiveRequest,
            inviteHandler,
            cancelInviteHandler,
            endStreamHandler,
            removeInviteHandler,
            liveVideoStatus,
            streamervOption,
            secondPlayerAllowed,
            // chatIsAtBottom,
            //
            sendLiveLikes,
            resetLiveLikes,
            liveLikeCount,
            likeCount,
            popUpAllowed,
            fileForm,
            form,
            popUpBannerAllow,
            updateBannerHandler,
            hideBannerHandler,
            showBannerHandler,
            bannerStatus,
        };
    },
};
</script>
<style scoped>
.scrollarea {
    /* width: 200px; */
    min-height: 500px;
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 2px;
}
.messages {
    border: 1px solid rgba(152, 152, 152, 0.119);
    min-width: 100px;
    /* min-height: 30px; */
    margin: 2px 5px;
    padding: 0px 5px;
    border-radius: 10px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6026785714285714) 23%, rgba(62, 62, 62, 0.6446953781512605) 100%);
}
#parag {
    white-space: normal;
    direction: rtl;
    text-align: justify;
    padding-right: 0px;
    margin: 0px 0px 0px 0px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 18px;
}
.modalparag {
    font-size: 25px;
    line-height: 30px;
}
.chat-background {
    background-color: rgba(70, 70, 70, 0.081);
    border-radius: 10px;
}
.maxheight {
    max-height: 600px;
}
.secondMaxheight {
    max-height: 1300px;
}
.modalMsgHeight {
    min-height: 700px;
}
#msgButton {
    margin-top: -40px;
}
.bannedList {
    max-width: 500px;
    background-color: rgba(255, 235, 205, 0.155);
}
/* ul {
    list-style-type: none;
} */
.strearequest {
    max-width: 700px;
}
</style>
<style>
video {
    max-height: inherit !important;
}
</style>
